.portfolio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }
  
  .buttons-container {
    display: flex;
    flex-direction: column; /* Stack the drop zones vertically */
    width: 100%;
    margin-bottom: 1rem;
    gap: 1rem; /* Add space between the Include and Exclude sections */
  }
  
  .dropzone {
    margin: 0 1rem;
    padding: 0.3rem; /* Further reduced padding */
    background-color:#0C0040; /* Tailwind's gray-800 */
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    min-height: 50px; /* Ensure the box doesn't collapse entirely */
    transition: min-height 0.3s ease;
  }
  
  .dropzone h3 {
    color: #60a5fa; /* Tailwind's blue-400 */
    text-align: center;
    margin-bottom: 0.3rem; /* Further reduced margin */
  }
  
  .dropzone:not(:empty) {
    min-height: 100px; /* Smaller size when tags are present */
  }
  
  .projects-display {
    width: 100%;
  }
  
  .projects-heading {
    font-size: 1.5rem;
    color: #60a5fa; /* Tailwind's blue-400 */
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .projects-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem; /* Reduced gap between project cards */
  }
  
  /* Styles for the tag buttons */
  .tagbutton {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 0.2rem 0.6rem; /* Further reduced padding */
    margin: 0.2rem 0; /* Further reduced margin */
    font-family: inherit;
    font-size: 12px; /* Smaller font size */
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 700;
    border-radius: 500px;
    overflow: hidden;
    background: #5700b3;
    color: ghostwhite;
  }
  
  .tagbutton span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
  }
  
  .tagbutton:hover span {
    color: black;
  }
  
  .tagbutton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 100%;
    background: #000;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  }
  
  .tagbutton:hover::before {
    transform: translate3d(100%, 0, 0);
  }
  
  /* Responsive design for mobile screens */
  @media (max-width: 768px) {
    .buttons-container {
      flex-direction: column; /* Stack Include and Exclude vertically */
    }
  
    .dropzone {
      width: 100%; /* Full width on mobile */
      margin: 0 auto;
      padding: 0.2rem; /* Reduced padding for mobile */
    }
  
    .projects-list {
      gap: 1rem; /* Smaller gap between project cards on mobile */
    }
  }
  
  /* Add this to your CSS */
/* Adjusted Modal Styles */

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #1f2937; /* Tailwind's gray-800 */
    padding: 0.5rem; /* Reduced padding */
    border-radius: 10px;
    outline: none;
    max-width: 60%; /* Reduced width */
    max-height: 60%; /* Reduced height */
    overflow: auto;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .close-button {
    background: #7103d8; /* Tailwind's blue-400 */
    color: white;
    padding: 0.3rem; /* Reduced padding */
    font-size: 0.75rem; /* Reduced font size */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    margin-bottom: 0.5rem; /* Adjust spacing */
  }
  
  img {
    max-width: 100%; /* Ensure the image fits within the modal */
    height: auto;
    border-radius: 8px;
  }
  
  .placeholder-text {
    color: #9ca3af; /* Tailwind's gray-400 */
    font-size: 0.875rem; /* Tailwind's text-sm */
    text-align: center;
    margin-top: 1rem;
  }

  
  .download-container {
    margin-top: 4rem;
    text-align: center;
  }
  
  .download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem 2rem;
    font-size: 0.875rem; /* Tailwind's text-sm */
    font-weight: 600;
    text-transform: uppercase;
    background: linear-gradient(to right, #ec4899, #8b5cf6);
    color: white;
    border-radius: 9999px; /* Tailwind's rounded-full */
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }
  
  .download-button:hover {
    gap: 1rem;
    background: linear-gradient(to right, #db2777, #6d28d9);
    text-decoration: none;
  }
  
  .download-button svg {
    transition: transform 0.2s ease-in-out;
  }
  
  .download-button:hover svg {
    transform: translateX(0.25rem);
  }
  