.skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-bottom: 2%;
  background-color: #0C0040; /* Default background color */
  transition: background-color 0.5s ease;
  border-radius: 12px; /* Rounded corners */
  font-family: 'Montserrat', sans-serif; /* Updated font family */
}

.skills-heading {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.line {
  width: 6rem;
  height: 2px;
  background-color: #1a1443;
}
.Bigline {
  width: 6rem;
  height: 2px;
  background-color: #2507e4;
}

.heading-text {
  background-color: #1a1443;
  color: white;
  padding: 0.5rem 2rem;
  font-size: 1.25rem;
  text-align: center;
  border-radius: 0.5rem;
  margin: 0 1rem;
}

.skills-text {
  max-width: 80%; /* Updated width for better alignment */
  color: white;
  text-align: center; /* Centered text */
  margin-bottom: 1rem; /* Add some spacing below */
}

.skills-cloud {
  max-width: 100%;
  display: flex;
  overflow: hidden; /* Hide overflow */
}

.scroller__inner {
  display: flex;
  gap: 3rem;
  white-space: nowrap;
  animation: scroll-left 80s linear infinite;
}

.skill-item {
  position: relative;
  padding: 3px;
  background-color:#1a1443; 
  border-radius: 0.9em;
  transition: all 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 7rem; /* Fixed width to ensure square shape */
  height: 6rem; /* Fixed height to ensure square shape */
  box-shadow: 2px 2px 3px #000000b4;
}

.skill-item::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  border-radius: 0.9em;
  z-index: -10;
  filter: blur(0);
  transition: filter 0.4s ease;
}

.skill-item:hover::before {
  background: linear-gradient(90deg,  #2405ad, #f441a5);
  filter: blur(1.2em);
}

.skill-item:active::before {
  filter: blur(0.2em);
}

.skill-item .skill-icon {
  margin-bottom: 0.5rem;
  margin-TOP: 0.5rem;
}

.skill-img {
  height: 3rem;
  width: auto;
}

.skill-name {
  font-size: 1rem;
  color: #a49d9d;
}



@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .skill-item {
    width: 5rem; /* Adjust width */
    height: 5rem; /* Adjust height */
  }

  .skill-img {
    height: 2rem; /* Adjust image size */
  }
}
