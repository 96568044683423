.projects-container {
    padding: 2rem;
    background-color: #171717;
    border-radius: 20px;
  }
  
  .projects-heading {
    text-align: center;
    color: #b003a7;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .project-card {
    background-color: #191919;
    padding: 1.5rem;
    border-radius: 20px;
    box-shadow: inset 2px 5px 10px rgb(5, 5, 5), 0 0 15px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 1.5rem;
  }
  
  .project-title {
    font-size: 1.5rem;
    color: #b003a7;
    margin-bottom: 0.5rem;
  }
  
  .project-description {
    font-size: 1rem;
    color: #ccd6f6;
    margin-bottom: 1rem;
  }
  
  .project-link {
    color: #b003a7;
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.3s ease;
  }
  
  .project-link:hover {
    color: #6e6ef4;
  }
  
  .error-message {
    color: #ff4d4f;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1rem;
  }
  
  @media (max-width: 768px) {
    .projects-heading {
      font-size: 2rem;
    }
  
    .project-title {
      font-size: 1.25rem;
    }
  
    .project-description {
      font-size: 0.875rem;
    }
  }
  