/* Default Dark Mode Styles */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: url('./assets/images/blobback.png') no-repeat center center fixed;
  background-size: cover;
  color: white; /* Default text color for dark mode */
}

.App {
  text-align: center;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.video-container video {
  width: 200px;
  height: auto;
}


/* CHATBOT 
.chatbot-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  border-radius: 16px;  
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chatbot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.chatbot-title {
  font-size: 1rem; 
  font-weight: bold;
}

.chatbot-close {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.chatbox {
  flex: 1;
  max-height: 400px;
  overflow-y: auto;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.message {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
}

.message.user {
  background-color: #007bff;
  color: white;
  text-align: right;
}

.message.bot {
  background-color: #f1f1f1;
  color: black;
  text-align: left;
}

.chatbot-input {
  display: flex;
  margin-top: 1rem;
}

input[type="text"] {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 0.5rem;
}

button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.bg-blue-500 {
  background-color: #007bff;
}

.text-white {
  color: white;
}

.p-3 {
  padding: 0.75rem;
}

.rounded-full {
  border-radius: 9999px;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: #d1d5db;
}

.w-80 {
  width: 20rem;
}

.h-96 {
  height: 24rem;
}

.p-4 {
  padding: 1rem;
}

*/