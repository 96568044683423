.card {
    padding: 1rem;
    overflow: hidden;
    border: 1px solid #c5c5c5;
    border-radius: 12px;
    background-color: #d9d9d92f;
    backdrop-filter: blur(8px);
    min-width: 344px;
    max-height: 300px;
  }
  
  .wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    z-index: 10;
    border: 0.5px solid #525252;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .terminal {
    display: flex;
    flex-direction: column;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  }
  
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    min-height: 40px;
    padding-inline: 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #202425;
  }
  
  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 2.5rem;
    user-select: none;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #8e8e8e;
  }
  
  .title > svg {
    height: 18px;
    width: 18px;
    margin-top: 2px;
    color: #006adc;
  }
  
  .copy_toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    border: 0.65px solid #c1c2c5;
    margin-left: auto;
    border-radius: 6px;
    background-color: #202425;
    color: #8e8e8e;
    cursor: pointer;
  }
  
  .copy_toggle > svg {
    width: 20px;
    height: 20px;
  }
  
  .copy_toggle:active > svg > path,
  .copy_toggle:focus-within > svg > path {
    animation: clipboard-check 500ms linear forwards;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow-x: auto;
    padding: 1rem;
    line-height: 19px;
    color: white;
    background-color: black;
    white-space: nowrap;
  }
  
  .pre {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-wrap: nowrap;
    white-space: pre;
    background-color: transparent;
    overflow: hidden;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  .pre code:nth-child(1) {
    color: #575757;
  }
  
  .pre code:nth-child(2) {
    color: #e34ba9;
  }
  
  .cmd {
    height: 19px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .cmd::before {
    content: attr(data-cmd);
    position: relative;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    background-color: transparent;
    animation: inputs 8s steps(22) infinite;
  }
  
  .cmd::after {
    content: "";
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    background-color: transparent;
    border-right: 0.15em solid #e34ba9;
    animation: cursor 0.5s step-end infinite alternate, blinking 0.5s infinite;
  }
  
  @keyframes blinking {
    20%,
    80% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(0);
    }
  }
  
  @keyframes cursor {
    50% {
      border-right-color: transparent;
    }
  }
  
  @keyframes inputs {
    0%,
    100% {
      width: 0;
    }
    10%,
    90% {
      width: 58px;
    }
    30%,
    70% {
      width: 215px;
      max-width: max-content;
    }
  }
  
  @keyframes clipboard-check {
    100% {
      color: #fff;
      d: path(
        "M 9 5 H 7 a 2 2 0 0 0 -2 2 v 12 a 2 2 0 0 0 2 2 h 10 a 2 2 0 0 0 2 -2 V 7 a 2 2 0 0 0 -2 -2 h -2 M 9 5 a 2 2 0 0 0 2 2 h 2 a 2 2 0 0 0 2 -2 M 9 5 a 2 2 0 0 1 2 -2 h 2 a 2 2 0 0 1 2 2 m -6 9 l 2 2 l 4 -4"
      );
    }
  }
  
  .terminal-card-position {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(10deg) translate(20%, -20%);
    transition: transform 0.3s ease-in-out, scale 0.3s ease-in-out;
}

/* Decrease size on smaller screens or when zoomed in */
@media (max-width: 768px) {
  .terminal-card-position {
    transform: scale(0.8) rotate(10deg) translate(20%, -20%);
  }
}

@media (max-width: 480px) {
  .terminal-card-position {
    transform: scale(0.6) rotate(10deg) translate(20%, -20%);
  }
}

/* Ensure card takes less space on very small screens */
@media (max-width: 160px) {
  .terminal-card-position {
    transform: scale(0.5) rotate(10deg) translate(20%, -20%);
  }
}
  