.form {
    display: flex;
    flex-direction: column;
    align-self: center;
    font-family: inherit;
    gap: 10px;
    padding-inline: 2em;
    padding-bottom: 0.4em;
    background-color: #171717;
    border-radius: 20px;
  }
  
  .form-heading {
    text-align: center;
    margin: 2em;
    color: #b003a7;
    font-size: 1.2em;
    background-color: transparent;
    align-self: center;
  }
  
  .form-field {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    border-radius: 10px;
    padding: 0.6em;
    border: none;
    outline: none;
    color: white;
    background-color: #171717;
    box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
  }
  
  .input-field {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    color: #ffffff;
    padding-inline: 1em;
  }
  
  .sendMessage-btn {
    cursor: pointer;
    margin-bottom: 3em;
    padding: 1em;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #9606a1;
    font-weight: bold;
    outline: 1px solid #c40ddc;
    transition: all ease-in-out 0.3s;
  }
  
  .sendMessage-btn:hover {
    transition: all ease-in-out 0.3s;
    background-color: #9606a1;
    color: #f9f9f9;
    cursor: pointer;
    box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
  }
  
  .form-card1 {

    background: linear-gradient(to right, #020464, #2f0458, #600331);
    border-radius: 22px;
    transition: all 0.3s;
  }
  
  .form-card1:hover {
    box-shadow: 0px 0px 30px 1px rgba(75, 0, 250, 0.3);
  }
  
  .form-card2 {
    border-radius: 0;
    transition: all 0.2s;
  }
  
  .form-card2:hover {
    transform: scale(0.98);
    border-radius: 20px;
  }
  