.contact-container {
    padding: 2rem;
    background-color: #171717;
    border-radius: 20px;
  }
  
  .contact-heading {
    text-align: center;
    color: #b003a7;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .contact-details-container {
    margin-top: 2rem;
  }
  
  .contact-card {
    background-color: #191919;
    padding: 1.5rem;
    border-radius: 20px;
    box-shadow: inset 2px 5px 10px rgb(5, 5, 5), 0 0 15px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-icon {
    font-size: 2.5rem;
    color: #b003a7;
    margin-right: 1.5rem;
  }
  
  .contact-card-heading {
    color: #b003a7;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .contact-card-link {
    color: #b003a7;
    font-size: 1rem;
    text-decoration: underline;
    transition: color 0.3s ease;
  }
  
  .contact-card-link:hover {
      color: #b003a7;
  }
  
  @media (max-width: 768px) {
    .contact-heading {
      font-size: 2rem;
    }
    
    .contact-card-heading {
      font-size: 1.25rem;
    }
    
    .contact-card-link {
      font-size: 0.875rem;
    }
  }
  