.experience-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align all cards */
  gap: 2rem;
  padding: 2rem;
}

.card2-container {
  width: 300px;
  height: 300px;
  position: relative;
  border-radius: 10px;
}

.card2-container::before {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: linear-gradient(to right, #020464, #2f0458, #600331);
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
}

.card2 {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
}

.card2 .img-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #020464, #2f0458, #600331);
  transition: transform 0.6s, rotate 0.6s, filter 1s;
}

.card2 .img-content img {
  width: 60%;
  height: auto;
  object-fit: cover;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card2 .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  color: #e8e8e8;
  font-size: large;
  font:bold;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(50px);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card2 .content .heading {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(to right, #00a4f1, #74028b);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

}


.card2:hover .content {
  opacity: 1;
  transform: translateY(0);
}

.card2:hover .img-content {
  transform: scale(2.5) rotate(30deg);
  filter: blur(7px);
}

.card2:hover .img-content img {
  opacity: 0.7;
}


