.about-me-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
    flex-direction: row; /* Default to side by side for larger screens */
  }
  
  @media screen and (max-width: 768px) {
    .about-me-container {
      flex-direction: column; /* Stack on top of each other for smaller screens */
      align-items: center;
      text-align: center;
    }
  
    .card-container {
      margin-right: 0; /* Remove margin when stacked */
      margin-bottom: 2rem; /* Add space below the card when stacked */
    }
  
    .about-me-box {
      width: 100%; /* Ensure the box takes full width */
    }
  
    .about-me-box h2 {
      font-size: 1.5rem;
    }
  
    .about-me-text p {
      font-size: 0.9rem;
    }
  }
  
  .card-container {
    flex: 0 0 30%;
    margin-right: 2rem;
  }
  
  .about-me-text {
    flex: 1;
    color: white;
    padding: 1rem;
  }
  
  .about-me-box h2 {
    color: white;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .about-me-text p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .about-me-box p {
    font-size: 1rem;
    line-height: 1.5;
    text-align: justify;
    color: white;
  }
  
  .highlight-gradient {
    background: linear-gradient(to right, #ff8c00, #ff0080);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .card {
    width: 230px;
    height: 300px;
    border-radius: 2em;
    padding: 10px;
    background-color: #191919;
    box-shadow: 5px 5px 30px rgb(4, 4, 4), -5px -5px 30px rgb(57, 57, 57);
    transition: transform 0.3s ease, filter 0.3s ease;
  }
  
  .card:hover .pfp {
    transform: scale(1.1);
    filter: grayscale(0%);
  }
  
  .profileimage {
    margin-top: 1rem;
    width: 150px;
    height: 150px;
  }
  
  .pfp {
    width: 100%;
    height: auto;
    border-radius: 50%;
    filter: grayscale(100%);
    transition: transform 0.3s ease, filter 0.3s ease;
  }
  
  .socialbar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #191919;
    border-radius: 3em;
    width: 90%;
    padding: 14px;
    margin-top: 40px;
    color: white;
    box-shadow: 3px 3px 15px rgb(0, 0, 0), -3px -3px 15px rgb(58, 58, 58);
  }
  
  .card a {
    transition: 0.4s;
    color: white;
  }
  
  #github:hover {
    color: #c9510c;
  }
  
  #instagram:hover {
    color: #d62976;
  }
  
  #linkedin:hover {
    color: #0077b5;
  }
  
  #whatsapp:hover {
    color: #25D366;
  }
  